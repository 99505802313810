<template>
  <div>
    <b-card
      v-if="commentData"
      title="in4Startups Yorumu"
    >
      {{ commentData.content }}
      <div class="text-muted mt-2">
        <div v-if="commentData.modified">
          {{ moment(commentData.modified).format('LLLL') }} tarihinde güncellendi.
        </div>
        <div v-else>
          {{ moment(commentData.created).format('LLLL') }} tarihinde eklendi.
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'Comment',
  components: {
    BCard,
  },
  props: {
    commentData: {
      type: Object,
      default: null,
    },
  },
}
</script>
