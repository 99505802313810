<template>
  <div class="mt-2">
    <b-row>
      <b-col
        v-for="(techBoxes, key) in techologyReadiness"
        :key="key"
        class="mb-1"
      >
        <div
          class="tech-card text-center p-1 rounded"
          :style="'background-color:' + techBoxes.bg_color"
        >
          <h4 class="font-weight-bolder text-white">
            {{ techBoxes.title }}
          </h4>
          <h5 class="text-white">
            {{ techBoxes.avg_score }}%
          </h5>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mt-3"
      >
        <div class="bar-graphic position-relative">
          <div
            class="bar-line position-absolute"
            :style="'left: ' + techologyReadinessScore + '%'"
          />
          <b-row>
            <b-col class="labels">
              {{ $t('Bilgi Geliştirme') }}
            </b-col>
            <b-col class="labels">
              {{ $t('Teknoloji Geliştirme') }}
            </b-col>
            <b-col class="labels">
              {{ $t('İş Geliştirme') }}
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'MarketGraphic',
  components: {
    BRow,
    BCol,
  },
  computed: {
    techologyReadiness() {
      return this.$store.getters['dealerReportTechnologyReadiness/getTechnologyReadinessList']
    },
    techologyReadinessScore() {
      return this.$store.getters['dealerReportTechnologyReadiness/getTechnologyReadinessScore']
    },
  },
}
</script>

<style scoped lang="sass">
.bar-graphic
  background: linear-gradient(90deg, rgba(227,0,0,1) 0%, rgba(224,194,0,1) 50%, rgba(70,184,0,1) 100%)
  height: 120px
  border-radius: 25px
  overflow: hidden
  .labels
    color: #ffffff
    font-weight: bold
    text-align: center
    height: 120px
    line-height: 120px
    font-size: 0.875rem
    text-wrap: none
  .bar-line
    height: 100%
    background-color: #3b42f4
    width: 7px
    z-index: 10000
</style>
